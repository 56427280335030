import React, { useState, useEffect } from 'react';
import './Slideshow.css';

import image1 from "../assets/images/callofduty/597965.jpg";
import image2 from "../assets/images/fortnite/889823.jpg";
import image3 from "../assets/images/leagueoflegends/1332615.jpeg";
import image4 from "../assets/images/leagueoflegends/1350568.jpeg";
import image5 from "../assets/images/leagueoflegends/1353219.png";
import image6 from "../assets/images/leagueoflegends/1357169.png";
import image7 from "../assets/images/leagueoflegends/1359045.jpeg";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7
];

const Slideshow = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        (prevIndex + 1) % images.length
      );
    }, 3000); // Muda a imagem a cada 3 segundos

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, []);

  return (
    <div className="slideshow">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Slide ${index + 1}`}
          className={`slide ${index === currentImageIndex ? 'active' : ''}`}
        />
      ))}
    </div>
  );
};

export default Slideshow;
