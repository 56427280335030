import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slideshow from './slideShow';

const images = [
  'https://via.placeholder.com/800x400?text=Imagem+1',
  'https://via.placeholder.com/800x400?text=Imagem+2',
  'https://via.placeholder.com/800x400?text=Imagem+3',
];


const Countdown = () => {
    // Defina a data final aqui (exemplo: 30 de Novembro de 2024)
    const targetDate = new Date('2024-11-30T00:00:00').getTime();  
    const [timeLeft, setTimeLeft] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    });
  
    useEffect(() => {
      const calculateTimeLeft = () => {
        const now = new Date().getTime();
        const distance = targetDate - now;
  
        if (distance > 0) {
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  
          setTimeLeft({ days, hours, minutes, seconds });
        } else {
          clearInterval(interval);
          setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        }
      };
  
      const interval = setInterval(calculateTimeLeft, 1000);
  
      return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    }, [targetDate]);

    

  return (
    <div className='sec-countdown'>
      <Slideshow />
      <div className='shadow'>
        <h1>Tradenexus<span>.gg</span></h1>
        <div className='countdown'>
          <div className='countdown__child'>{timeLeft.days} dias</div>
          <div className='countdown__child'>{timeLeft.hours} horas</div>
          <div className='countdown__child'>{timeLeft.minutes} minutos</div>
          <div className='countdown__child'>{timeLeft.seconds} segundos</div>
        </div>
        <p>Desenvolvido por </p> <Link to="https://www.instagram.com/pleed.is/" target='_blank'>@pleed.is</Link>
        
      </div>
    </div>
  );
};

export default Countdown;