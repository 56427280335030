import Countdown from "./comming/contador";

function App() {
  return (
    <div className="App">
      <Countdown />
    </div>
  );
}

export default App;
